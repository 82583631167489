@import "../../styles/vars/global";

.footer {
  display: flex;
  flex-direction: column;
  background-color: $color-blue-300;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 100px 50px 100px;
  color: $color-beige-300;
  position: relative;
}

.line {
  position: absolute;
  background-color: #E2D4C9;
  width: 125vw;
  height: 20px;
  top: 231px;
  left: -187px;
  z-index: 3;
  rotate: -26deg;
}

.lineTwo {
  position: absolute;
  background-color: #E2D4C9;
  width: 115vw;
  height: 20px;
  top: 737px;
  left: -159px;
  z-index: 3;
  rotate: 22deg;
}

.box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image {
  width: 800px;
  height: 800px;
  background-image: url('../../assets/images/footer.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  z-index: 3;
  // box-shadow: -9px -6px 34px 1px rgba(226, 212, 201, 0.25), 9px 6px 34px 1px rgba(226, 212, 201, 0.25);
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info__title {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.info__button {
  z-index: 2;
  width: 300px;
  height: 80px;
  background: $color-fiolet-300;
  border-radius: 5px;
  color: $color-white;
  font-weight: 900;
  font-size: 35px;
  margin-top: 100px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navig {
  display: flex;
  gap: 25px;
  border-bottom: 1px solid $color-beige-300;
  padding-bottom: 30px;
  width: 50%;
}

.navig__link {
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
  color: $color-beige-300;
}

.navig__link:hover {
  opacity: .7;
}

.contacts {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 30px;
}

.contacts__item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contacts__link {
  text-decoration: none;
  color: $color-beige-300;
}

.contacts__link:hover {
  opacity: .7;
}

.contacts__icon {
  width: 37px;
  height: 37px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.contacts__icon_phone {
  background-image: url('../../assets/images/phone.svg');
}

.contacts__icon_map {
  background-image: url('../../assets/images/map.svg');
}

.contacts__icon_email {
  background-image: url('../../assets/images/email.svg');
}

.contacts__address {
  font-weight: 600;
  font-size: 22px;
}

.contacts__text {
  font-weight: 600;
  font-size: 22px;
  text-decoration: none;
  color: $color-beige-300;
}

.contacts__text:hover {
  opacity: .7;
  cursor: pointer;
}

.social {
  display: flex;
  gap: 20px;
}

.social__icon {
  color: transparent;
  width: 37px;
  height: 37px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.social__icon_vk {
  background-image: url('../../assets/images/vk.svg');
}

.social__icon_ok {
  background-image: url('../../assets/images/ok.svg');
}

.social__icon:hover {
  opacity: .7;
}

@media screen and (min-width: 2250px) and (max-width: 3000px) {
  .line {
    top: 150px;
    rotate: -21deg;
  }
}

@media screen and (min-width: 320px) and (max-width: 1549px) {
  .line {
    top: 263px;
    rotate: -21deg
  }
  
  .lineTwo {
    top: 600px;
    rotate: 27deg;
    width: 125vw;
  }

  .navig {
    width: 65%;
  }
}

@media screen and (min-width: 320px) and (max-width: 1399px) {
  .navig__link {
    font-size: 15px;
  }

  .contacts__text {
    font-size: 15px;
  }

  .contacts__address {
    font-size: 15px;
  }

  .contacts__icon {
    width: 27px;
    height: 27px;
  }
  
  .social__icon {
    width: 27px;
    height: 27px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1249px) {
  .line {
    top: 293px;
    rotate: -27deg;
  }
  
  .lineTwo {
    top: 500px;
    rotate: 27deg;
  }
}

@media screen and (min-width: 320px) and (max-width: 1099px) {
  .info__title {
    font-size: 30px;
  }

  .info__button {
    width: 250px;
    height: 60px;
  }

  .navig {
    width: 70%;
  }
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .line {
    width: 125vw
  }

  .navig {
    width: 80%;
  }
}

@media screen and (min-width: 320px) and (max-width: 899px) {
  .line {
    top: 300px;
    rotate: -32deg
  }
  
  .lineTwo {
    top: 500px;
    rotate: 27deg;
    width: 125vw;
  }

  .navig {
    width: 90%;
  }
}

@media screen and (min-width: 320px) and (max-width: 849px) {
  .footer {
    padding: 0 50px 50px 50px;
  }

  .line {
    top: 276px;
    rotate: -27deg;
    width: 145vw;
  }
  
  .lineTwo {
    top: 520px;
    rotate: 39deg;
    width: 145vw;
  }

  .info__button {
    margin-top: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 749px) {
  .navig {
    width: 95%;
  }
}

@media screen and (min-width: 320px) and (max-width: 699px) {
  .navig {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
}

@media screen and (min-width: 320px) and (max-width: 649px) {
  .footer {
    padding: 0 10px 50px 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 549px) {
  .footer {
    padding: 0 10px 50px 10px;
  }

  .info__title {
    font-size: 20px;
  }

  .info__button {
    width: 200px;
    height: 40px;
    font-size: 20px;
  }

  .lineTwo {
    width: 155vw;
  }

  .navig {
    padding-bottom: 15px;
  }

  .navig__link {
    font-size: 12px;
  }

  .contacts__text {
    font-size: 12px;
  }

  .contacts__address {
    font-size: 12px;
  }

  .contacts__icon {
    width: 17px;
    height: 17px;
  }
  
  .social__icon {
    width: 17px;
    height: 17px;
  }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
  .image {
    width: 50%;
  }

  .line {
    top: 244px;
    rotate: -38deg;
    width: 155vw;
  }

  .lineTwo {
    top: 494px;
    rotate: 44deg;
  }

}

@media screen and (min-width: 320px) and (max-width: 449px) {
  .image {
    margin: -30px 0px 0 0;
  }

  .lineTwo {
    width: 165vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 399px) {
  .info__title {
    font-size: 15px;
  }

  .info__button {
    width: 150px;
    height: 30px;
    font-size: 15px;
  }

  .line {
    top: 306px;
    rotate: -31deg;
  }

  .lineTwo {
    top: 430px;
    rotate: 32deg;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .line {
    top: 293px;
    rotate: -37deg;
    width: 175vw;

  }

  .lineTwo {
    top: 434px;
    rotate: 43deg;
    width: 175vw;
  }
}
