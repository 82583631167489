@import "../../styles/vars/global";

.header {
  display: flex;
  flex-direction: column;
  // background-image: url('../../assets/images/header.jpg');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: contain;
  background-color: $color-blue-300;
  padding: 0 100px 190px 100px;
  
  // border-bottom: 68px solid $color-beige-300;
  // clip-path: polygon(0% 0%, 0% 100%, 100% 70%, 100% 0%);
  // transform: rotate(-9deg);
  // height: 677px;
  position: relative;
}

.header:before {
  content: '';
  position: absolute;
  width: 115%;
  height: 115%;
  bottom: 8%;
  right: 0;
  z-index: 0;
  background-image: url('../../assets/images/header.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(-8deg);
}

// .header::before {
//   display: block;
//   content: '';
//   width: 100%;
//   height: 46px;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   // transform: translateY(100%) skewY(-9deg);
//   // transform-origin: left;
//   // background-color: red;

//   // transform: translateY(100%);
//   // background: linear-gradient(to bottom right, red 50%, rgba(0, 0, 0, 0) 51%);
// }

// .item {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   min-height: 320px;
//   background-color: $color-blue-300;
//   position: relative;
// }

// .item::before {
//   display: block;
//   content: '';
//   width: 100%;
//   height: 46px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   transform: translateY(-100%) skewY(-9deg);
//   transform-origin: right;
//   background: $color-beige-300;
//   // z-index: -1;
// }


// .item {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   min-height: 320px;
//   background-color: $color-blue-300;
//   position: relative;
// }

// .item::before {
//   display: block;
//   content: '';
//   width: 100%;
//   height: 46px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   transform: translateY(-100%) skewY(-9deg);
//   transform-origin: right;
//   background: $color-beige-300;
//   // z-index: -1;
// }

.separator {
  clip-path: polygon(0% 86%, 100% 2%, 100% 100%, 0% 100%);
  background: #E2D4C9;
  width: 100%;
  height: 200px;
  margin: -310px 0 0 0;
}

.item {
  clip-path: polygon(0% 91%, 100% 0%, 100% 100%, 0% 100%);
  background-color: $color-blue-300;
  margin: -162px 0 0 0;
  height: 180px;
}

.top {
  display: flex;
  justify-content: space-between;
}

.logo {
  z-index: 2;
  background-image: url('../../assets/images/logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 333px;
  height: 163px;
  margin-top: 55px;
}

.navig {
  z-index: 2;
  display: flex;
  gap: 16px;
  margin-top: 75px;
}

.navig__link {
  font-weight: 600;
  font-size: 20px;
  color: $color-beige-300;
  text-decoration: none;
}

.navig__link:hover {
  opacity: .7;
  cursor: pointer;
}

.title {
  z-index: 2;
  color: $color-white;
  font-weight: 900;
  font-size: 30px;
  margin-top: 115px;
  width: 50%;
}

.subTitle {
  z-index: 2;
  color: $color-beige-300;
  font-weight: 500;
  font-size: 30px;
  margin-top: 20px;
  width: 42%;
}

.button {
  z-index: 2;
  width: 300px;
  height: 80px;
  background: $color-fiolet-300;
  border-radius: 5px;
  color: $color-white;
  font-weight: 900;
  font-size: 35px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
}

.button:hover {
  opacity: .7;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 1649px) {
  .header:before {
    bottom: 6%;
  }
}

@media screen and (min-width: 320px) and (max-width: 1399px) {
  .logo {
    width: 208px;
    height: 106px;
    margin-top: 30px;
  }

  .title {
    font-size: 25px;
    margin-top: 90px;
  }
  
  .subTitle {
    font-size: 25px;
    margin-top: 20px;
  }

  .button {
    font-size: 25px;
    margin: 70px 0 50px 0;
  }

  .navig {
    margin-top: 50px;
  }

  .navig__link {
    font-size: 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1299px) {
  .title {
    font-size: 20px;
  }

  .subTitle {
    font-size: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1099px) {
  .logo {
    width: 160px;
    height: 80px;
  }

  .button {
    width: 200px;
    height: 60px;
    font-size: 25px;
  }
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .header {
    padding: 0 50px 190px 50px;
  }

  .navig {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
}

@media screen and (min-width: 320px) and (max-width: 899px) {
  .title {
    font-size: 16px;
  }
  
  .subTitle {
    font-size: 16px;
  }

  .button {
    width: 150px;
    height: 40px;
    font-size: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 699px) {
  .logo {
    width: 114px;
    height: 57px;
    margin: 20px 0 0 0;
  }

  .navig {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 20px 0 0 0;
  }
  
  .title {
    margin-top: 50px;
    width: 70%;
  }

  .subTitle {
    width: 50%;
  }

}

@media screen and (min-width: 320px) and (max-width: 599px) {
  .header {
    padding: 0 20px 190px 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
  .navig__link {
    text-align: right;
    font-size: 12px;
  }
}

@media screen and (min-width: 320px) and (max-width: 399px) {
  .logo {
    width: 72px;
    height: 35px;
  }

  .title {
    width: 90%;
  }
  
  .subTitle {
    width: 70%;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .navig__link {
    font-size: 10px;
  }

  .title {
    font-size: 14px;
  }
  
  .subTitle {
    font-size: 14px;
  }
}
