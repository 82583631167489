@import "../../styles/vars/global";

.container {
  width: 100%;
  background-color: $color-blue-300;
}

.partnert {
  display: flex;
  flex-direction: column;
  background-color: $color-blue-300;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 100px;
  color: $color-beige-300;
}

.partnert:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 10%;
  left: 0;
  z-index: 0;
  transform: rotate(-9deg);
}

.separator {
  clip-path: polygon(0% 58%, 100% 0%, 100% 35%, 0% 100%);
  background: #E2D4C9;
  width: 100%;
  // height: 375px;
  height: 420px;
  margin: -93px 0 0 0;
  position: relative;
  z-index: 2;
}

.item {
  // clip-path: polygon(0% 91%, 100% 0%, 100% 100%, 0% 100%);
  // background-color: #060B22;
  // margin: -162px 0 0 0;
  margin: -195px 0 0 0;
  height: 180px;
  position: relative;
  z-index: 2;
}

.titleBox {
  width: 100%;
  margin: -175px 0 80px 0;
}

.title {
  max-width: 437px;
  font-weight: 700;
  font-size: 40px;
  position: relative;
  z-index: 3;
  color: $color-blue-300;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 541px;
  background: rgba(226, 212, 201, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 126px;
}

.listA {
  display: flex;
  gap: 4px;
  margin-bottom: 50px;
}

.listB {
  display: flex;
  gap: 4px;
  margin-bottom: 50px;
}

.listC {
  display: flex;
  gap: 4px;
}

.list__item {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.list__image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 110px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
}

@media screen and (min-width: 320px) and (max-width: 1449px) {
  .titleBox {
    margin: -185px 0 80px 0;
  }

  .list {
    height: 740px;
  }

  .listA {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .listB {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 1299px) {
  .titleBox {
    margin: -195px 0 80px 0;
  }

  .item {
    margin: -190px 0 0 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 1149px) {
  .titleBox {
    margin: -205px 0 80px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 1399px) {
  .separator {
    margin: -100px 0 0 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .separator {
    margin: -95px 0 0 0;
  }

  .titleBox {
    margin: -175px 0 80px 0;
  }

  .title {
    font-size: 30px;
    width: 235px;
  }
}

@media screen and (min-width: 320px) and (max-width: 899px) {
  .titleBox {
    margin: -195px 0 180px 0;
  }

  .list {
    height: 940px;
  }

  .listA {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .listC {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 799px) {
  .partnert {
    padding: 0 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 699px) {
  .title {
    font-size: 25px;
  }

  .titleBox {
    margin: -175px 0 120px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 599px) {
  .titleBox {
    margin: -195px 0 120px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 549px) {
  .list__image {
    width: 150px;
  }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
  .titleBox {
    margin: -205px 0 120px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 459px) {
  .titleBox {
    margin: 0 0 50px 0;
  }

  .title {
    color: $color-beige-300;
  }

  .list__image {
    width: 130px;
  }

  .listA {
    margin-bottom: 30px;
  }

  .listB {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 399px) {
  .list__image {
    width: 110px;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .list__image {
    width: 95px;
  }
}



