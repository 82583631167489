@import "../../styles/vars/global";

.services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 100px;
  background-color: $color-beige-300;
  padding: 0 100px;
  color: $color-blue-300;
  position: relative;
}

.services::before {
  display: block;
  content: '';
  width: 100%;
  height: 145px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  background: linear-gradient(to top right, $color-beige-300 50%, rgba(255, 0, 0, 0) 51%);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 120px 0;
  z-index: 1;
}

.info {
  display: flex;
  flex-direction: column;
}

.info__title {
  font-weight: 700;
  font-size: 40px;
  margin: -220px 0 0 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 50px;
}

.list__item {
  display: flex;
  gap: 25px;
  align-items: center;
}

.list__image {
  min-width: 90px;
  min-height: 90px;
  background-color: $color-beige-300;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.list__image_one {
  background-image: url('../../assets/images/iconStoreBusket.png');
}

.list__image_two {
  background-image: url('../../assets/images/iconHouse.png');
}

.list__image_three {
  background-image: url('../../assets/images/circleGear.png');
}

.list__image_four {
  background-image: url('../../assets/images/gear.png');
}

.list__text {
  font-weight: 600;
  font-size: 20px;
  max-width: 585px;
}

.image {
  background-image: url('../../assets/images/services.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 600px;
  height: 600px;
}

@media screen and (min-width: 320px) and (max-width: 1549px) {
  .list__text {
    font-size: 15px;
  }
  .image {
    margin: 0 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .services {
    padding: 0 50px;
  }

  .info__title {
    font-size: 25px;
  }
}

@media screen and (min-width: 320px) and (max-width: 799px) {
  .image {
    display: none;
  }

  .list {
    margin: 20px 0 50px 0;
  }

  .info__title {
    margin: -80px 0 0 0;
  }

  .list__image {
    min-width: 50px;
    min-height: 50px;
  }
}
