@import "vars/reset";
@import "vars/global";
@import "global/resize";

.app {
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  font-family: "Exo 2", sans-serif;
  color: $color-blue-300;
  font-size: 16px;
  overflow: hidden;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.line {
  position: absolute;
  background-color: #E2D4C9;
  width: 115vw;
  height: 84px;
  top: 711px;
  left: -143px;
  z-index: 3;
  rotate: 22deg;
}

.lineTwo {
  position: absolute;
  background-color: #E2D4C9;
  width: 115vw;
  height: 27px;
  z-index: 3;
  rotate: -11deg;
  bottom: 543px;
  right: -61px;
}

@media screen and (min-width: 2250px) and (max-width: 3000px) {
  .line {
    left: -285px;
    width: 117vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 1349px) {
  .line {
    width: 130vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 599px) {
  .line {
    width: 140vw;
  }
}

@media screen and (min-width: 320px) and (max-width: 449px) {
  .line {
    width: 150vw;
  }
}
