@import "../../styles/vars/global";

.advantages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  background-color: $color-blue-300;
  position: relative;
}

.advantages::before {
  display: block;
  content: '';
  width: 100%;
  height: 145px;
  // background: black;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  background: linear-gradient(to top right, $color-blue-300 50%, rgba(255, 0, 0, 0) 51%);
}   

.title {
  font-weight: 700;
  font-size: 40px;
  color: $color-beige-300;
  margin-bottom: 50px;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list__title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: #E2D4C9;
  border: 5px solid #E2D4C9;
  border-radius: 50%;
  box-shadow: 6px 0px 4px -1px rgba(0, 0, 0, 0.25);
  color: $color-fiolet-300;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
}

.list__subtitle {
  font-weight: 500;
  font-size: 16px;
  color: $color-beige-300;
  max-width: 200px;
  text-align: center;
}

.list__subtitle_dark {
  color: $color-blue-300;
  max-width: 180px;
  z-index: 3;
}

@media screen and (min-width: 320px) and (max-width: 1299px) {
  .advantages {
    padding: 0 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1049px) {
  .advantages {
    padding: 0 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .list__title {
    width: 150px;
    height: 150px;
    margin-bottom: 50px;
  }

  .list__title {
    font-size: 25px;
  }

  .list__subtitle {
    font-size: 13px;
  }

  .title {
    font-size: 25px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 899px) {
  .list__subtitle_dark {
    margin-top: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 799px) {
  .list__subtitle_dark {
    margin: 0;
    color: $color-beige-300;
  }

  .list {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 320px) and (max-width: 699px) {
  .list__title {
    width: 100px;
    height: 100px;
  }

  .list__subtitle {
    font-size: 12px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 599px) {
  .list {
    flex-direction: column;
  }

  .list__title {
    width: 200px;
    height: 200px;
    margin-bottom: 25px
  }

  .list__subtitle {
    font-size: 13px;
    margin-bottom: 20px;
  }
}




// @media screen and (min-width: 320px) and (max-width: 1399px) {
//   .list__title {
//     // margin-bottom: 30px;
//   }
// }

// @media screen and (min-width: 320px) and (max-width: 1549px) {
//   .list__text {
//     font-size: 15px;
//   }
//   .image {
//     margin: 0 50px;
//   }
// }

// @media screen and (min-width: 320px) and (max-width: 999px) {
//   .services {
//     padding: 0 50px;
//   }

//   .info__title {
//     font-size: 25px;
//   }
// }

// @media screen and (min-width: 320px) and (max-width: 799px) {
//   .image {
//     display: none;
//   }

//   .list {
//     margin: 20px 0 50px 0;
//   }
// }

// @media screen and (min-width: 320px) and (max-width: 399px) {
//   .list__image {
//     min-width: 50px;
//     min-height: 50px;
//   }
// }