@import "../../styles/vars/global";

.about {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: $color-blue-300;
  padding: 0 100px 140px 100px;
  color: $color-beige-300;
}

.info {
  display: flex;
  flex-direction: column;
}

.info__title {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 50px;
}

.info__text {
  max-width: 800px;
  font-weight: 600;
  font-size: 20px;
}

.profile {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile__img {
  // background-color: #ccc;
  background-image: url('../../assets/images/about.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 495px;
  height: 603px;
  margin-bottom: 15px;
}

.profile__info {
  display: flex;
  flex-direction: column;
}

.profile__name {
  font-size: 16px;
}

.profile_bold {
  font-weight: 700;
}

@media screen and (min-width: 320px) and (max-width: 1549px) {
  .info__text {
    font-size: 15px;
  }

  .profile__img {
    width: 395px;
    height: 503px;
  }

  .profile {
    margin-left: 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 999px) {
  .profile__img {
    width: 295px;
    height: 403px;
    margin-bottom: 50px;
  }

  .info__title {
    font-size: 25px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 919px) {
  .info__text {
    font-size: 13px;
  }
}

@media screen and (min-width: 320px) and (max-width: 799px) {
  .profile {
    margin-left: 0;
  }

  .about {
    flex-direction: column-reverse;
  }

  .info {
    margin-bottom: 50px;
  }

  .info__title {
    margin-top: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 599px) {
  .about {
    padding: 0 50px 80px 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 399px) {
  .profile__img {
    width: 195px;
    height: 203px;
  }
}