@import "../../styles/vars/global";

.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  background-color: $color-blue-300;
  color: $color-beige-300;
}

.title {
  width: 100%;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 50px;
}

.list {
  width: 100%;
  display: grid;
  gap: 80px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 3;
  position: relative;
  text-decoration: none;
  color: $color-beige-300;
}

.list__item:hover {
  opacity: .7;
  cursor: pointer;
}

.list__image {
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  // background: url(.jpg), linear-gradient(0deg, rgba(113, 137, 194, 0.3), rgba(113, 137, 194, 0.3)), url();
  box-shadow: 1px 1px 10px 1px #E2D4C9, 1px 1px 10px 1px #E2D4C9;
}

.list__subtitle {
  font-weight: 500;
  font-size: 16px;
  max-width: 200px;
  text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 1649px) {
  .advantages {
    padding: 0 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1449px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 1199px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 899px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 699px) {
  .list {
    gap: 40px;
  }

  .list__image {
    width: 150px;
    height: 150px;
  }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
  .title {
    font-size: 30px;
  }

  .list__image {
    width: 130px;
    height: 130px;
  }

  .list__subtitle {
    font-size: 13px;
  }
}

@media screen and (min-width: 320px) and (max-width: 449px) {
  .list {
    gap: 20px;
  }

  .list__image {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 320px) and (max-width: 399px) {
  .advantages {
    padding: 0 10px;
  }

  .title {
    padding: 0 0 0 30px;
  }
}