$color-white: #ffffff;

$color-black: #000000;

$color-blue-300: #060B22;

$color-beige-300: #E2D4C9;

$color-fiolet-300: #58507A;

// Размеры
$m: 800px;
